<template>
  <section id="fundraising" class="fun-facts-area ptb-100">
    <div class="container">
      <div class="section-title mb-4">
        <h2>Peace is Possible with your Support</h2>
        <p>Be part of the solution</p>
      </div>

      <div class="fundraising-btn mb-5" v-if="isHomePage">
        <router-link to="/donate">Become A Partner In Peace <i class="flaticon-curve-arrow-1"></i></router-link>
      </div>

      <TestimonialsArea />
    </div>
  </section>
</template>

<script>
  import TestimonialsArea from "./TestimonialsArea";
  export default {
    name: "Fundraising",
    components: {TestimonialsArea},
    props: ['isHomePage'],
  }
</script>

<style scoped lang="scss">
  .section-title {
    p {
      font-size: 16px;
    }

    h2 {
      font-size: 35px;
    }
  }
  .blog-item {
    padding: 0;

    .content {
      padding: 25px;
    }

    .read-all {
      background: #bdbdbd;
      padding: 0 9px;
      border-radius: 5px;
      color: #fff;
      cursor: pointer;
      outline: none;
    }

    .price {
      display: flex;

      .post-meta {
        width: 100%;
        margin-top: 0;

        li {
          width: 100%;
          display: flex;
          align-items: center;

          p {
            list-style-type: none;
            display: inline-block;
            font-size: 15px;
            color: #767676;
            margin-right: 20px;
            font-weight: 500;
          }

          i {
            font-size: 18px;
            margin-right: 10px;
          }
        }
      }
    }
  }

  .fundraising-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    a {
      font-size: 15px;
      color: #ffffff;
      font-weight: bold;
      display: inline-block;
      background: #7e72f2;
      padding: 10px 20px;
      border-radius: 5px;
      transition: .3s;

      &:hover {
        background-color: #289bad;
      }
    }
  }
</style>
