<template>
  <div style="height: 200px" class="loading" v-if="testimonials_data === undefined && !testimonials_error">
    <Loading />
  </div>
  <section v-else-if="testimonials_data">
      <carousel
        class="testimonials-slider owl-theme"
        :responsive="{0:{items:1},600:{items:1},900:{items:2}}"
        :dots="false"
        :margin="30"
        :nav="false"
      >
        <template slot="prev">
          <span class="prev">
            <i class="flaticon-curve-arrow"></i>
          </span>
        </template>

        <div :class="'testimonials-item ' + 'color-' + testimonial.type.id" v-for="testimonial in testimonials_data.data" :key="testimonial.id">
          <div class="content">
            <div class="image">
              <DefaultImage v-if="testimonial.image" :id="testimonial.image.id" :width="'120px'" :height="'120px'" :class-name="'border-radius'" />

              <div class="icon">
                <i class="flaticon-left-quotes-sign"></i>
              </div>
            </div>

            <div class="text">
              <h3>{{testimonial.name}}</h3>
              <span class="d-block">{{testimonial.title}}</span>
              <p class="d-inline white-space-pre-wrap" v-if="testimonial.description" v-html="trimMyString(testimonial.description, 160)"></p>
              <span class="d-inline ml-1 read-all"
                    v-if="testimonial.description && checkStringLength(testimonial.description, 160)"
                    :content="testimonial.description" v-tippy='{ trigger : "focus", hideOnClick : false }'
              >Read all</span>
            </div>
          </div>
          <div class="dot"></div>
        </div>

        <template slot="next">
          <span class="next">
            <i class="flaticon-curve-arrow-1"></i>
          </span>
        </template>
      </carousel>
  </section>
</template>

<script>
  import carousel from 'vue-owl-carousel';
  import DefaultImage from "./DefaultImage";
  import Loading from "./Loading";
  import api from "../services/api";
  import useSWRV from "swrv";

  export default {
    name: "TestimonialsArea",
    components: {
      Loading,
      DefaultImage,
      carousel
    },
    setup() {
      let {
        data: testimonials_data,
        error: testimonials_error,
        isValidating: testimonials_isValidating
      } = useSWRV(() => `items/testimonials?status=published&sort-sort&fields=id,description,image,name,title,type.*`, api.fetcher);

      return {
        testimonials_data,
        testimonials_error,
        testimonials_isValidating
      }
    },
    methods: {
      trimMyString(string, maxLength, start = 0) {
        if (string.length > maxLength) {
          let trimmedString = string.substr(start, maxLength)
          return (
              trimmedString.substr(
                  start,
                  Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
              )
          )
        }
        return string
      },
      checkStringLength(string, maxLength) {
        return string.length > maxLength;
      }
    },
  }
</script>

<style scoped lang="scss">
  .testimonials-slider {
    position: relative;

    .next, .prev {
      position: absolute;
      top: 50%;
      transform: translateX(-50%);
      z-index: 2;
      font-size: 20px;
      cursor: pointer;
    }

    .next {
      right: -44px;
    }

    .prev {
      left: -25px;
    }
  }
  .testimonials-item {
    min-height: 200px;
    padding: 22px 30px;

    &.color-1 {
      border: 2px solid #0fdbaf;

      .dot::before {
        border-right-color: #0fdbaf;
      }
    }
    &.color-2 {
      border: 2px solid #FCC540;

      .dot::before {
        border-right-color: #FCC540;
      }
    }
    &.color-3 {
      border: 2px solid #FC4040;

      .dot::before {
        border-right-color: #FC4040;
      }
    }
    &.color-4 {
      border: 2px solid #786CF0;

      .dot::before {
        border-right-color: #786CF0;
      }
    }
    &.color-6 {
      border: 2px solid #26C6DA;

      .dot::before {
        border-right-color: #26C6DA;
      }
    }
  }
  .testimonials-item .content .image {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .testimonials-item .content .image .icon {
    color: #fff;
    position: absolute;
    bottom: 0;
    width: 40px;
    height: 40px;
    background: #32c0d6;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .read-all {
    font-size: 14px;
    background: #c5c5c5;
    color: #fff !important;
    padding: 0 9px;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
  }

  @media (max-width: 768px) {
    .testimonials-item {
      .content {
        display: flex;
        flex-direction: column;
        padding: 0;

        .image {
          width: 100%;
          position: revert;
          transform: translateY(0);
          display: flex;
          justify-content: center;
        }
      }
    }
    .testimonials-slider {
      .next, .prev {
        top: revert;
        bottom: -40px;
        transform: revert;
      }

      .next {
        right: 10px;
      }

      .prev {
        left: 10px;
      }
    }
  }
</style>
